<template>
  <v-data-table
    :headers="headers"
    :items="overDueInvoices"
    :footer-props="footerProps"
    :items-per-page="7"
    :loading="loading"
    :options.sync="options"
    :server-items-length="totalLength"
  >
    <!-- Rent -->
    <template #[`item.invoiceNumber`]="{ item }">
      <router-link
        style="text-decoration: none"
        :to="`/invoices?tab=${0}&search=${item.invoiceNumber}`"
      >
        <span class="font-weight-bold"># {{ item.invoiceNumber }}</span>
      </router-link>
    </template>

    <!-- Apartment Address -->
    <template #[`item.address`]="{ item }">
      <router-link
        style="text-decoration: none"
        :to="`/apartment/overview/${item.contract.apartment.id._id}`"
      >
        <span>{{ item.contract.apartment.id.address }}</span>
        <span v-if="item.contract.apartment.id.apartmentNumber"
          >, {{ item.contract.apartment.id.apartmentNumber }}
        </span>
      </router-link>
    </template>
    <!-- ContractNumber -->
    <template #[`item.contractNumber`]="{ item }">
      <span>{{ item.contract.contractNumber }} </span>
    </template>

    <!-- dueDate -->
    <template #[`item.dueDate`]="{ item }">
      <span>{{ formatDate(item.dueDate) }} </span>
    </template>

    <!-- Total amount -->
    <template #[`item.totalAmount`]="{ item }">
      <span class="font-weight-bold success--text">{{ formatCurrency(item.totalAmount) }}</span>
    </template>

    <!-- Open amount -->
    <template #[`item.openAmount`]="{ item }">
      <span class="font-weight-bold error--text">{{ formatCurrency(item.openAmount) }}</span>
    </template>

    <template #no-data>
      <h2>Ei erääntyneitä laskuja</h2>
    </template>
  </v-data-table>
</template>

<script>
import mixins from "../../mixins/mixins";
import globalValues from "@/configs/globalValues";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  mixins: [mixins],
  data: () => ({
    loading: true,
    dialog: false,
    options: {},
    page: 1,
    footerProps: {
      "items-per-page-options": [7, 10, 15, 20, 30],
      showFirstLastPage: true,
      firstIcon: "mdi-arrow-collapse-left",
      lastIcon: "mdi-arrow-collapse-right",
      prevIcon: "mdi-minus",
      nextIcon: "mdi-plus",
    },
    headers: [
      {
        text: "Laskun nro.",
        value: "invoiceNumber",
      },
      {
        text: "Osoite",
        value: "address",
        sortable: false,
      },

      {
        text: "Sopimus",
        value: "contractNumber",
        sortable: false,
      },

      {
        text: "Eräpäivä",
        value: "dueDate",
      },
      {
        text: "Saatavat",
        value: "totalAmount",
      },
      {
        text: "Avoinna",
        value: "openAmount",
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("invoice", ["totalLength", "overDueInvoices"]),
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations("browsehistory", ["saveTableOptions"]),
    ...mapActions("invoice", ["getOverdueInvoices"]),

    async getDataFromApi() {
      try {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.loading = true;

        await this.getOverdueInvoices(
          `/api/v1/invoices/apartment-invoices?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}`
        );

        this.loading = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
