<template>
  <v-card height="150px">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline">{{ title }}</div>

        <p class="small-text mb-3">
          {{ this.updatedAt ? `Päivitetty ${this.updatedAt}` : "" }}
        </p>

        <v-list-item-title class="headline mb-1">
          {{ amount }}
        </v-list-item-title>

        <v-list-item-subtitle>
          <p class="subtitle">
            {{ subtitle }}
            <span>
              <v-tooltip v-if="tooltip" bottom max-width="400">
                <template #activator="{ on }">
                  <v-icon small color="primary" v-on="on">mdi-help-circle</v-icon>
                </template>
                <span>{{ tooltip }}</span>
              </v-tooltip></span
            >
          </p>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar size="15">
        <v-icon :color="color" small>mdi-{{ icon }}</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    icon: { type: String, default: "" },
    amount: { type: String, default: "" },
    color: { type: String, default: "" },
    tooltip: { type: String, default: "" },
    updatedAt: { type: String, default: "" },
  },
};
</script>

<style scoped>
.subtitle {
  font-size: 12px !important;
  margin-top: 2px;
}
.small-text {
  font-size: 10px !important;
  margin-top: 2px;
}
</style>
