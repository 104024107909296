<template>
  <v-container fluid>
    <!-- Talous -->
    <h3 class="mt-1">Kuluvan kuukauden laskutus</h3>
    <small class="mb-1">
      {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
    </small>

    <v-row dense class="mb-1">
      <!-- Bills -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Saatavat"
          subtitle="Kuluvan kk saatavat"
          icon="file-document-outline"
          :amount="`${formatCurrency(stats.invoice.currentMonthTotalAmount)}`"
          color="primary"
        ></basic-card>
      </v-col>
      <!-- Paid -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Suoritukset"
          subtitle="Kuluvan kk suoritukset"
          icon="currency-eur"
          :amount="`${formatCurrency(
            stats.invoice.currentMonthTotalAmount - stats.invoice.currentMonthOpenAmount
          )}`"
          color="success"
        ></basic-card>
      </v-col>

      <!-- waiting -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Avoinna"
          subtitle="Kuluvan kk avoimet saatavat"
          icon="alert-circle"
          :amount="`${formatCurrency(stats.invoice.currentMonthOpenAmount)}`"
          color="warning"
        ></basic-card>
      </v-col>
    </v-row>

    <h3 class="mt-1">Kokonaislaskutus</h3>
    <small class="mb-1">
      {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
    </small>

    <v-row dense>
      <!-- waiting -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Avoinna"
          subtitle="Kaikki avoimet saatavat"
          icon="alert-circle"
          :amount="`${formatCurrency(stats.invoice.totalOpenAllTime)}`"
          color="warning"
        ></basic-card>
      </v-col>
      <!-- overdue -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Erääntyneet"
          subtitle="Kaikki erääntyneet saatavat"
          icon="clock-alert-outline"
          :amount="`${formatCurrency(stats.invoice.totalOverdueAllTime)}`"
          color="error"
        ></basic-card>
      </v-col>
    </v-row>

    <h3 class="mt-1">Tavoitteet</h3>
    <small class="mb-1">
      {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
    </small>

    <v-row dense>
      <!-- rent calculations -->
      <v-col cols="12">
        <v-card min-height="183px">
          <v-list-item three-line>
            <v-list-item-content>
              <p class="overline">Markkinavuokrat yhteensä</p>
              <strong class="headline">{{
                formatCurrency(stats.apartment.totalEstimatedRent)
              }}</strong>

              <p class="mt-1 overline">Laskutettavat vuokrat yhteensä</p>

              <strong class="headline">{{ formatCurrency(stats.invoice.rentIncomes) }}</strong>

              <p class="mt-1 overline">Markkinavuokrasta saavutettu</p>
              <v-progress-linear
                :value="
                  stats.apartment.totalEstimatedRent
                    ? (stats.invoice.rentIncomes / stats.apartment.totalEstimatedRent) * 100
                    : 0
                "
                color="primary"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.round(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-list-item-content>

            <v-list-item-avatar size="15">
              <v-icon small>mdi-scale-balance</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="mt-1 mb-1">
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <h3>Maksukäyttäytyminen</h3>
        <small class="mb-1">
          {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
        </small>

        <basic-card
          title="Maksuviive"
          subtitle="Viim. 365 päivän keskimääräinen maksuviive"
          icon="clock-outline"
          :amount="
            stats.payments.yearAvgPaymentDelay
              ? `${Math.round(stats.payments.yearAvgPaymentDelay)} päivää`
              : 'Ei laskettavissa'
          "
          :color="
            Math.round(stats.payments.yearAvgPaymentDelay) <= 1
              ? 'success'
              : Math.round(stats.payments.yearAvgPaymentDelay) > 1 &&
                Math.round(stats.payments.yearAvgPaymentDelay) <= 7
              ? 'warning'
              : 'error'
          "
        ></basic-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <h3>Kassavirta</h3>
        <small class="mb-1">
          {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
        </small>
        <basic-card
          title="Kassavirta"
          subtitle="Kuluvan kuun kassavirta ennen veroja"
          icon="cash-multiple"
          :color="calcCashFlow() < 0 ? 'error' : 'success'"
          :amount="`${formatCurrency(calcCashFlow())}`"
          tooltip="Kuluvan kuukauden saatavat - hoitovastikkeet - rahoitusvastikkeet - vesimaksut - lainanlyhennykset - muut kulut. Mikäli lainoja ei ole vielä lyhennetty tässä kuussa, ne eivät näy laskelmassa."
        ></basic-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <h3>Varallisuus</h3>
        <small class="mb-1">
          {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
        </small>
        <basic-card
          title="Varallisuuden kasvu"
          :subtitle="
            stats.updateTime
              ? `Ennen veroja ${
                  globalValues.months[new Date(stats.updateTime).getMonth()]
                }ssa ${new Date(stats.updateTime).getFullYear()}`
              : 'Ennen veroja'
          "
          icon="currency-eur"
          :color="calcGrowtOfWealth() < 0 ? 'error' : 'success'"
          :amount="`${formatCurrency(calcGrowtOfWealth())}`"
          tooltip="Kuluvan kuun kassavirta + kuluvan kuun lainojen pääomien lyhennykset. Mikäli lainoja ei ole vielä lyhennetty tässä kuussa, ne eivät näy laskelmassa."
        ></basic-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import BasicCard from "../../components/Statistics/BasicCard";
import { mapState } from "vuex";
import globalValues from "../../configs/globalValues";

export default {
  components: {
    BasicCard,
  },
  mixins: [mixins],
  data() {
    return {
      currentMonthTotalAmount: 0,
      currentMonthOpenAmount: 0,
      totalOpenAmount: 0,
      totalOverdueAmount: 0,
      globalValues,
    };
  },

  computed: {
    ...mapState("statistics", ["stats"]),
  },

  methods: {
    calcCashFlow() {
      const totalIncomes = this.stats.invoice.currentMonthTotalAmount;
      const maintenanceTotal = this.stats.apartment.maintenanceChargesThisMonth;
      const waterTotal = this.stats.apartment.waterChargesThisMonth;
      const apartmentLoanPayments = this.stats.apartment.loanPaymentThisMonth;
      const loansTotal = this.stats.loans.totalAmountThisMonth;
      const receiptsTotal = this.stats.receipt.receiptsThisMonth;

      return (
        totalIncomes -
        maintenanceTotal -
        waterTotal -
        apartmentLoanPayments -
        loansTotal -
        receiptsTotal
      );
    },

    calcGrowtOfWealth() {
      const cashFlow = this.calcCashFlow();
      const equityThisMonth = this.stats.loans.equityThisMonth;

      return cashFlow + equityThisMonth;
    },
  },
};
</script>

<style scoped></style>
