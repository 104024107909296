<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["options", "invoiceSums", "charges"],

  mounted() {
    this.renderChart(
      {
        labels: [
          "Tammikuu",
          "Helmikuu",
          "Maaliskuu",
          "Huhtikuu",
          "Toukokuu",
          "Kesäkuu",
          "Heinäkuu",
          "Elokuu",
          "Syyskuu",
          "Lokakuu",
          "Marraskuu",
          "Joulukuu",
        ],
        datasets: [
          {
            label: "Vuokrat",
            backgroundColor: "#0096c7",
            borderColor: "#0096c7",
            data: this.invoiceSums,
            fill: false,
          },
          {
            label: "Kulut",
            backgroundColor: "#ef476f",
            borderColor: "#ef476f",
            data: this.charges,
            fill: false,
          },
        ],
      },
      this.options
    );
  },
};
</script>

<style></style>
