var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.overDueInvoices,"footer-props":_vm.footerProps,"items-per-page":7,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.invoiceNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/invoices?tab=" + (0) + "&search=" + (item.invoiceNumber))}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.invoiceNumber))])])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/apartment/overview/" + (item.contract.apartment.id._id))}},[_c('span',[_vm._v(_vm._s(item.contract.apartment.id.address))]),(item.contract.apartment.id.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.contract.apartment.id.apartmentNumber)+" ")]):_vm._e()])]}},{key:"item.contractNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.contract.contractNumber)+" ")])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dueDate))+" ")])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(_vm._s(_vm.formatCurrency(item.totalAmount)))])]}},{key:"item.openAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v(_vm._s(_vm.formatCurrency(item.openAmount)))])]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei erääntyneitä laskuja")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }