<script>
import { Pie } from "vue-chartjs";
import { mapState } from "vuex";

export default {
  extends: Pie,

  props: ["ownEquity", "fontFamily"],

  data() {
    return {
      options: {
        legend: {
          display: true,
          align: "center",
          labels: {
            fontFamily: this.fontFamily,
            fontColor: "#6e707e",
            fontSize: 13,
            padding: 5,
          },
        },
        title: {
          fontFamily: this.fontFamily,
          display: false,
          position: "top",
          text: `Pääomien suhde`,
          fontSize: 15,
          fontColor: "#6e707e",
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          bodyFontFamily: this.fontFamily,
          titleFontFamily: this.fontFamily,
          backgroundColor: "#ffffff",
          bodyFontColor: "#858796",
          titleMarginBottom: 7,
          titleFontColor: "#6e707e",
          bodyFontSize: 15,
          titleFontSize: 13,
          borderColor: "#aadfeb",
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: true,
          intersect: true,
          caretPadding: 10,
          mode: "index",
          callbacks: {
            title: function (tooltipItems, data) {
              const label = data.labels[tooltipItems[0].index];
              return label;
            },
            label: function (tooltipItem, data) {
              const value = data.datasets[0].data[tooltipItem.index];
              return `${value} %`;
            },
          },
        },
      },
    };
  },

  mounted() {
    const bankLoans = this.stats.loans.currentLoanAmount;
    const apartmentLoans = this.stats.apartment.housingCompanyLoans;
    const ownEquity = this.ownEquity <= 0 ? 0 : this.ownEquity;
    const total = bankLoans + apartmentLoans + ownEquity;

    const bankVal = total != 0 ? parseFloat(((bankLoans / total) * 100).toFixed(2)) : 0;
    const apartmentVal = total != 0 ? parseFloat(((apartmentLoans / total) * 100).toFixed(2)) : 0;
    const ownEquityVal = total != 0 ? parseFloat(((ownEquity / total) * 100).toFixed(2)) : 0;

    let labelVal = [];
    let dataVal = [];
    let colors = [];

    if (bankVal > 0) {
      labelVal.push("Pankkilainat");
      dataVal.push(bankVal);
      colors.push("#ef476f");
    }

    if (apartmentVal > 0) {
      labelVal.push("Yhtiölainat");
      dataVal.push(apartmentVal);
      colors.push("#ecb639");
    }

    if (ownEquityVal > 0) {
      labelVal.push("Oma pääoma");
      dataVal.push(ownEquityVal);
      colors.push("#00ba86");
    }

    // if something is pushed to array
    if (labelVal.length > 0) {
      this.renderChart(
        {
          labels: labelVal,
          datasets: [
            {
              backgroundColor: colors,
              data: dataVal,
              fill: true,
            },
          ],
        },
        this.options
      );
    } else {
      this.renderChart(
        {
          labels: ["Pankkilainat", "Yhtiölainat", "Oma pääoma"],
          datasets: [
            {
              backgroundColor: ["#ef476f", "#ecb639", "#00ba86"],
              data: [33.33, 33.33, 33.33],
              fill: true,
            },
          ],
        },
        this.options
      );
    }
  },

  computed: {
    ...mapState("statistics", ["stats"]),
  },
};
</script>

<style scoped></style>
